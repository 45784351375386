body,
.root {
	height: 100%;
}

.App {
	text-align: center;
}
.euiAccordion__childWrapper {
	overflow: visible !important;
}

.noBackground {
	background: none !important;
	border: none !important;
	box-shadow: none !important;
}

.euiStep__titleWrapper .euiTitle {
	width: 100%;
}

.euiDescribedFormGroup {
	margin: 0 auto;
}
